@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Rift;
  src: url("./assets/fonts/Fort\ Foundry\ -\ Rift-Regular.otf");
}

@font-face {
  font-family: Rift-Bold;
  src: url("./assets/fonts/Fort\ Foundry\ -\ Rift-Bold.otf");
}

@font-face {
  font-family: Open-Sans;
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: BebasNeue;
  src: url("./assets/fonts/BebasNeue-Regular.ttf");
}

body {
  margin: 0;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
  font-family: Rift;
}

.open-sans {
  font-family: Open-Sans;
}

.rift {
  font-family: Rift;
}

.rift-i {
  font-family: Rift !important;
}

.rift-bold {
  font-family: Rift-Bold;
}

.open-sans-i {
  font-family: Open-Sans !important;
}

@keyframes appear-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.appear {
  animation: appear-animation 0.3s;
}

.button-orange {
  width: 100%;
  padding: 10px 20px;
  font-family: rift-bold;
  border: 2px solid;
  font-weight: bolder;
  color: var(--dark);
  border: 1px solid var(--orange);
  background-color: var(--dark-orange);
}

.button-grey {
  width: 100%;
  padding: 10px 20px;
  font-family: rift-bold;
  border: 2px solid;
  font-weight: bolder;
  color: var(--orange);
  border: 1px solid var(--dark-ash);
  background-color: var(--light-ash);
}

.twire-top-border {
  border-top: 1px solid var(--orange);
  position: relative;
  overflow: hidden;
}

.twire-top-border::after {
  content: "";
  display: block;
  height: 16px;
  width: 27%;
  background-color: var(--orange);
  top: 0;
  right: 0;
  position: absolute;
  transform: skew(60deg) translateX(20px);
}

.error-message {
  padding: 10px 15px;
  border: 2px solid var(--pink-red);
  background-color: var(--pink-red-dark);
  font-family: Open-Sans;
}

.info-message {
  padding: 10px 15px;
  border: 1px solid var(--orange);
  background-color: var(--darkest-orange);
  font-family: Open-Sans;
}

.twire-radio {
  width: 16px;
  height: 16px;
  background: rgba(52, 55, 65, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.15);
  position: relative;
}

.twire-radio-active::after {
  content: "";
  display: block;
  position: absolute;
  background: #f0ab2e;
  height: 8px;
  width: 8px;
  left: 50%;
  margin-left: -4px;
  top: 50%;
  margin-top: -4px;
}

.success-message {
  padding: 10px 15px;
  border: 2px solid var(--green);
  background-color: rgba(72, 177, 69, 0.3);
  font-family: Open-Sans;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #18191d;
  z-index: 100000000000;
  padding: 40px 2.5vw 40px 2.5vw;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.iframe-center iframe {
  margin: 0 auto;
}
