@import "../../../../../styles/breakpoints";

.right {
  max-width: 280px;
  // min-width: 280px;
  margin-left: 20px;
  width: 100%;
  @media (max-width: mq(tournament, tab)) {
    margin-top: 40px;
  }
  @media (max-width: mq(news, mobile)) {
    flex-direction: column;
    max-width: unset;
    margin-left: 0;
  }
}

.left {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: mq(tournament, tab)) {
    margin-top: 40px;
  }
}

.leftInner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(200px, auto);
  grid-auto-columns: minmax(280px, auto);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  gap: 20px;
  @media (max-width: mq(news, tab)) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: mq(news, small)) {
    grid-template-columns: 1fr;
    gap: 4px;
    grid-column-gap: 4px;
    grid-row-gap: 4px;
  }
  & > * {
    height: 360px !important;
  }
  & > :first-child {
    grid-column: 1/3;
    grid-row: 1/3;
    @media (max-width: mq(news, small)) {
      grid-column: unset;
      grid-row: unset;
    }
  }
  & > :first-child,
  & > :nth-child(2),
  & > :nth-child(3) {
    height: unset !important;
    @media (max-width: mq(news, tab)) {
      height: 360px !important;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  @media (max-width: mq(news, mobile)) {
    flex-direction: column;
  }
}

.title {
  font-weight: bold;
  font-size: 24px;
  color: var(--light);
  margin-bottom: 5px;
}

.placeholder {
  background-color: var(--placeholder-color);
}
