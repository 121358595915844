.teamLogo {
  height: 60px;
  width: 60px;
}

.placeholderTitle {
  height: 60px;
  margin-left: 20px;
}

.header {
  margin-bottom: 10px;
}

.teamName {
  font-size: 2rem;
  color: var(--light);
  margin-left: 20px;
}

.infoSection {
  & > :first-child {
    color: #f0ab2e;
    font-weight: bold;
  }
  & > :nth-child(2) {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: var(--light);
  }
}

.flagIcon {
  width: 24px;
  height: 16px;
  margin-left: 10px;
}
