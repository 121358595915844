.statHeaders {
    button {
        font-size: 16px !important;
        // @media (max-width: 1321px) {
        //     max-width: unset;
        // }
    }
    // &:last-child {
    //     margin-right: 8px;
    // }
}

.container {
    min-height: fit-content;
    background-color: #343741;
}
.overallHeader {
    height: fit-content;
    font-weight: bold;
}
.playerName {
    min-width: 251px;
}

.arrows {
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    opacity: 0;
    width: 8px;
    flex-direction: column;
    position: absolute;
    right: -12px;
    top: 5px;
    & > div {
        display: flex;
        align-self: center;
        justify-content: center;
    }
    svg {
      height: 7px;
      width: 7px;
      margin: 0px;
     
    }
    .top {
      transform: rotate(180deg);
      margin-bottom: 4px;
    }
}

.visible {
    opacity: 1;
}
.fade {
    opacity: 0.5;
    svg {
        height: 6px;
        width: 6px;
      }
}
  
.button {
    display: inline-flex !important;
    position: relative !important;
    flex-direction: row !important;
}