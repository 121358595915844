@import "../../../../styles/breakpoints";

.cardBox {
  height: 40px;
}

.container {
  // height: calc(100vh - 80px);
  // min-height: fit-content;
  // @media (max-width: mq(featured, mobile)) {
  //   margin-top: 22px;
  // }
  display: flex;
  @media (max-width: mq(tournaments, tab)) {
    display: block !important;
  }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}

.content {
  padding: 20px;
  background: var(--grey);
  // height: 100%;
}

.heading {
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 10px;
  color: var(--light);
  font-family: Rift-Bold;
}

.smallHeading {
  font-size: 24px;
  line-height: 50px;
  margin-bottom: 10px;
  color: var(--light);
  font-family: Rift-Bold;
}

.right {
  min-width: 280px;
  max-width: 280px;
  margin-left: 20px;
  @media (max-width: mq(tournaments, tab)) {
    margin-left: 0;
    max-width: unset;
    width: 100%;
    margin-top: 20px;
  }
}

.left {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  a {
    display: block;
  }
}
