@import "../../../styles/mixins";

.container {
  height: 100vh;
  width: 100vw;
  background: rgba(32, 34, 41, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  .modal {
    position: relative;
    max-height: 90vh;
    margin: auto;
    background: rgb(24, 25, 29);
    padding: 15px 20px;
    overflow: auto;
    @include twire-scrollbar;
  }
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  svg {
    path {
      fill: var(--light);
    }
  }
}
