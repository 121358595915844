.indicatorBox {
  background-color: #18191d;
  z-index: 4;
  border: 2px solid var(--orange);
  border-left: 3px solid var(--orange);
  border-right: 0;
  border-bottom: 0;
  transform: skew(-45deg);
  //   max-height: 30px;
  padding: 20px 0px 1px 25px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  & > div {
    height: 17px;
    border: 3px solid var(--orange);
    margin: 0 5px;
    width: 18px;
  }
  &:last-child {
    margin-right: 10px;
  }
  &::after {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: -25px;
    background-color: #18191d;
  }
}
