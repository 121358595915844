@import "../../../styles/breakpoints";

.container {
  background-image: url("../../../assets/images/backgrounds/pubgbg.jpg");
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: calc(100vh - 80px);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formWrapper {
  position: relative;
  max-width: 480px;
  width: 100vw;
  background-color: var(--grey);
  padding: 40px;
  color: var(--light);
  background-image: url("../../../assets/images/abstract/triangles-bottom-right.png"),
    url("../../../assets/images/abstract/triangles-bottom-left.png");
  background-position: right bottom, left bottom;
  background-repeat: no-repeat;
  @media (max-width: mq(authPage, mobile)) {
    padding: 20px;
  }
  h1 {
    font-size: 50px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0%;
    line-height: 60px;
  }
  h6 {
    margin-bottom: 30px;
    font-family: Open-Sans;
    font-size: 14px;
    text-align: center;
  }
  input {
    margin-bottom: 20px;
  }
}

.bottomRight {
  position: absolute;
  bottom: 0;
  right: 0;
}
