@import "../../../../../styles/breakpoints";

.right {
  max-width: 280px;
  // min-width: 280px;
  margin-left: 20px;
  width: 100%;
  @media (max-width: mq(tournament, tab)) {
    max-width: unset;
    margin-left: 0;
    margin-top: 40px;
  }
}

.left {
  width: 100%;
}
.error {
  height: 80vh;
  margin-left: 2vw;
  margin-right: 2vw;
  margin-bottom: 20px;
}
.tournamentInfoWrapper {
  // width: 100%;
  background-color: var(--grey);
  padding: 20px;
  height: fit-content;
}
.container {
  display: flex;
  @media (max-width: mq(tournament, tab)) {
    flex-direction: column;
  }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}
