@import "../../../styles/breakpoints";

.container {
  padding: 25px 30px;
  width: 100%;
  margin: 4px 0px;
  min-height: 97px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  min-height: 97px;
  @media (max-width: mq(ranking, mobile)) {
    // flex-direction: column;
    padding: 20px 20px;
  }
}

.pos {
  font-weight: bolder;
  font-size: 20px;
  font-size: 20px;
  width: 30px;
  display: flex;
}

.teamLogo {
  width: 45px;
  height: 45px;
  margin-left: 30px;
  @media (max-width: mq(ranking, mobile)) {
    width: 35px;
    height: 35px;
    margin-left: 0px;
  }
}

// .region {
//   display: flex;
//   color: var(--light);
//   font-family: Open-Sans;
//   font-size: 12px;
//   align-items: center;
//   margin-left: 5%;
//   margin-right: 5%;
//   svg {
//     height: 24px;
//     width: 24px;
//     opacity: 0.3;
//     margin-right: 10px;
//   }
// }

.points {
  color: var(--light);
  font-family: Open-Sans;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 60px;
  white-space: nowrap;
  @media (max-width: mq(ranking, mobile)) {
    margin-left: auto;
    margin-right: 0;
  }
  svg {
    height: 24px;
    width: 24px;
    opacity: 0.3;
    margin-right: 10px;
  }
}

.first {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.second {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
  min-width: 330px;
  @media (max-width: mq(ranking, mobile)) {
    // min-height: 97px;
    width: fit-content;
    min-width: unset;
    margin-left: auto;
    margin-top: 0px;
    justify-content: flex-start;
  }
}

.team {
  font-size: 24px;
  color: var(--light);
  font-weight: bolder;
  margin-left: 20px;
}

.arrowSpanWrapper {
  @media (max-width: mq(ranking, mobile)) {
    margin-left: auto;
  }
}

.diff {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  opacity: 1 !important;
}

.up {
  color: var(--green);
  svg {
    path {
      fill: var(--green);
    }
  }
}

.down {
  color: var(--red);
  svg {
    path {
      fill: var(--red);
    }
  }
}
