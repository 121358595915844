.list {
  margin-top: 10px;
  & > * {
    display: block;
  }
  & > * {
    margin-bottom: 4px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}
