.container {
  overflow-x: hidden;
  position: relative;
  border-top: 1px solid var(--orange);
  padding: 30px 20px 20px 20px;
  background-size: cover !important;
  min-height: 196px;
  height: 100%;
  background-repeat: no-repeat !important;
  .inner {
    min-height: calc(196px - 50px - 1px);
  }
  p {
    color: var(--light);
  }
}

.hideBoarder {
  border-top: none;
}

.short {
  background: linear-gradient(
    180deg,
    rgba(32, 34, 41, 0.4) 0%,
    #202229 100%
  ) !important;
  min-height: fit-content !important;
  .inner {
    min-height: fit-content !important;
  }
  .caption {
    display: none !important;
  }
  .headline {
    font-size: 20px !important;
  }
  .base {
    border: none !important;
    padding-top: 0 !important;
  }
}

.top {
  height: 11px;
  width: 45%;
  background-color: var(--orange);
  top: 0;
  right: 0;
  position: absolute;
  transform: skew(60deg) translateX(20px);
}

.headline {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
}

.caption {
  font-family: Open-Sans;
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 15px;
}

.base {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 17px;
  display: flex;
  // margin-top: auto;
  span {
    font-size: 12px;
    color: var(--light);
  }
}

.bigFont {
  font-size: 16px;
}
