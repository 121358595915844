.button {
  color: var(--light);
  opacity: 0.3;
}

.active {
  color: var(--orange);
  text-decoration: underline;
  opacity: 1;
}

.top {
  display: flex;
  margin-top: 18px;
  margin-bottom: 10px;
}
