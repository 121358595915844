.nav {
  position: relative;
}

.navLink {
  svg {
    height: 20px;
    width: 20px;
    opacity: 0.3;
    path {
      transition: 0.3ss;
      fill: var(--light);
    }
  }
  p {
    color: var(--light);
    transition: 0.3s;
    margin: 10px 0 0 0;
    text-transform: uppercase;
    padding: 0 10px;
  }
}

.navItem {
  height: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  .indicatorWrapper {
    opacity: 0;
    right: 0;
    height: 75px;
    width: 0px;
    position: absolute;
    background-image: linear-gradient(
      270deg,
      rgba(240, 171, 46, 0.2) 0%,
      rgba(41, 42, 47, 0) 100%
    );
    pointer-events: none;
    transition: width 0.2s;
    .indicator {
      position: absolute;
      height: 100%;
      width: 2px;
      right: 0;
      background-color: var(--orange);
    }
  }
}

.blue {
  .indicatorWrapper {
    background-image: linear-gradient(
      270deg,
      rgba(90, 192, 236, 0.2) 0%,
      rgba(41, 42, 47, 0) 100%
    );
    .indicator {
      background-color: var(--blue);
    }
  }
}

.active {
  .blue {
    svg {
      opacity: 1;
      path {
        fill: var(--blue) !important;
      }
    }
    p {
      color: var(--blue) !important;
    }
  }
  svg {
    opacity: 1;
    path {
      fill: var(--orange);
    }
  }
  p {
    color: var(--orange);
  }
  .indicatorWrapper {
    opacity: 1;
    width: 43px;
  }
}
