@import "../../../../../../../styles/_mixins.scss";

$cell-width: 50px;
$team-width: 120px;
$small-cell-width: 40px;
$team-logo-size: 18px;

.th {
  td {
    color: var(--light);
    font-weight: bold;
    background: var(--dark-ash);
  }
}

.pl30 {
  padding-left: 30px;
}

.pr30 {
  padding-right: 30px;
}

.cell {
  height: 40px;
}

.paddedSpan {
  padding-left: 20px;
}

.container {
  width: 100%;
  position: relative;
  @include twire-scrollbar;
  overflow-x: auto;
  height: 80vh;
}

.table {
  position: absolute;
  top: 0;
  bottom: 0;
  border-collapse: collapse;
  width: 100%;
  td {
    padding: 0 !important;
    text-align: center;
  }
}

.alignLeftI {
  text-align: left !important;
}

.subHeading {
  color: rgba(255, 255, 255, 0.3);
  font-weight: bold;
  font-size: 16px;
}

.grey {
  color: rgba(255, 255, 255, 0.3) !important;
}

.fieldRow {
  td {
    height: 40px;
  }
  & > :nth-child(1) {
    color: var(--orange);
  }
  & > :nth-child(2) {
    color: var(--light);
    // display: flex;
    align-items: center;
    img {
      height: $team-logo-size;
      width: $team-logo-size;
      margin-right: 7px;
    }
  }
}

.stickyLeft {
  position: sticky;
  z-index: 1;
}

.indexSticky {
  left: 0;
}

.teamSticky {
  left: $cell-width;
}

.smallCell {
  min-width: $small-cell-width !important;
  max-width: $small-cell-width !important;
}

.smallDataCell {
  min-width: $small-cell-width !important;
  max-width: unset !important;
  // max-width: $small-cell-width !important;
}

.dataCellNormal {
  min-width: $cell-width !important;
  max-width: unset !important;
}

.total1Sticky {
  left: calc(#{$cell-width} + #{$team-width} + 5px);
}

.total2Sticky {
  left: calc(#{$small-cell-width} + #{$cell-width} + #{$team-width} + 5px);
}

.stickyTop {
  position: sticky;
  top: 0;
  z-index: 1;
}

.stickyTop40 {
  position: sticky;
  top: 40px;
  z-index: 1;
  background-color: #18191d;
}

.dataCell {
  min-width: $cell-width;
  max-width: $cell-width;
}

.name {
  // min-width: $team-width;
  // max-width: $team-width;
  width: $team-width;
  table {
    td:first-child {
      min-width: calc($team-logo-size + 5px);
    }
    td:nth-child(2) {
      max-width: calc($team-width - $team-logo-size);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
  }
}

.rank {
  //   min-width: 70px;
  min-width: $cell-width;
  max-width: $cell-width;
}

.smallPaddedSpan {
  padding-left: 10px;
}

.matchTitleCell {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.resizeButton {
  position: absolute;
  left: 2px;
  top: -10px;
  z-index: 5;
}
