.expandable {
  overflow-y: hidden;
  transition: height 0.4s ease-out;
  height: 0;
  // min-height: fit-content !important;
}

.open {
  // overflow-y: visible;
  // min-height: fit-content !important;
}