.radarChart {
  stroke-dasharray: 8.7;
  tspan {
    font-family: Rift;
    color: var(--light);
    font-size: 15px;
  }
  // text {
  //   stroke {
  //     color: var(--light);
  //   }
  // }
}

.radar {
  stroke-dasharray: 0;
}

.ploarGrid {
  display: none;
}

.icon {
  height: 20px !important;
  width: 20px !important;
  opacity: 0.7;
  path {
    fill: var(--light);
  }
}

.tick {
  cursor: pointer;
  pointer-events: bounding-box;
}
