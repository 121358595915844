@import "../../../styles/breakpoints";

@keyframes showOverflow {
  100% {
    opacity: 1;
  }
}

.layout {
  $skin-ad-width: 300px;
  // max-width: calc(100vw - #{$skin-ad-width * 2});
  max-width: 1920px;
}

.right {
  width: 100%;
}

.mainSection {
  // min-height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  & > * {
    padding: 22px 2vw 120px 2vw;
    @media (max-width: mq(featured, mobile)) {
      padding-top: 0;
    }
  }
}

.mainSectionWrapper {
  position: relative;
  box-sizing: border-box;
  height: fit-content;
  width: 100%;
  scroll-margin-top: 1000000px;
}

.hamburgerDropdownWrapper {
  transition: height 0.3s;
  position: sticky;
  top: 80px;
  overflow: hidden;
  display: none;
  z-index: 100;
}

@media (max-width: mq(layout, mobile)) {
  .desktopNav {
    display: none;
  }
  .hamburgerDropdownWrapper {
    display: block;
  }
}

.show {
  opacity: 0;
  animation: showOverflow 0.3s forwards;
  overflow: visible;
}
