@import "../../../../styles/breakpoints";

$width: 124px;
$tab-width: 94px;

.dropdownTrigger {
  height: 80px;
  width: $width;
  display: flex;
  justify-content: center;
  @media (max-width: mq(dropdown, mobile)) {
    width: $tab-width;
  }
  @media (max-width: mq(layout, mobile)) {
    height: 70px;
  }
}

.dropdown {
  width: $width;
  @media (max-width: mq(dropdown, mobile)) {
    width: $tab-width !important;
  }
}

.dropdownItem {
  display: flex;
  justify-content: center;
  height: 36px;
}

.flagIcon {
  width: 24px;
  height: 16px;
}
