@import "../../../../styles/breakpoints";

.container {
    display: flex;
    width: 100%;
    // @media (max-width: mq(playerRanking, tab)) {
    //   flex-direction: column;
    // }
    @media (max-width: mq(featured, mobile)) {
      margin-top: 22px;
    }
  }
  
  .right {
    max-width: 280px;
    margin-left: 20px;
    width: 100%;
    @media (max-width: mq(playerRanking, tab)) {
      max-width: unset;
      margin-left: 0px;
    }
    @media (max-width: 1300px) {
      display: none;
    }
  }
  
  .left {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
  }

  .navLinks {
    display: flex;
    background-color: var(--grey);
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 24px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    a {
      height: 60px;
      // max-width: 190px;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--light);
      font-weight: bold;
      min-width: fit-content;
    }
    .active {
      color: var(--orange);
      border-bottom: 2px solid var(--orange);
    }
  }
  
  .blue {
    justify-content: center;
    gap: 15%;
    @media (max-width: 500px) {
      gap: unset;
      justify-content: unset;
    }
    .active {
      color: var(--blue);
      border-bottom: 2px solid var(--blue);
    }
    a {
      max-width: 200px;
      @media (max-width: 500px) {
        max-width: unset;
      }
    }
  }