.container {
  background: #202229;
  height: 60px;
  display: flex;
  align-items: center;
  background-image: url("../../../assets/images/abstract/blue-banner-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  border-bottom: 2px solid var(--blue);
  text-align: center;
  justify-content: center;
  color: var(--light);
  font-family: Open-Sans;
  font-size: 14px;
  padding: 15px;
  @media (max-width: 720px) {
    height: unset;
    background-image: unset;
    justify-content: space-between;
  }
  span {
    text-shadow: 0px 0px 32px rgba(236, 181, 81, 0.3);
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin: 7px;
  }
  p {
    font-family: Open-Sans;
    display: flex;
    align-items: center;
    @media (max-width: 720px) {
      text-align: left;
      align-items: flex-start;
      padding-left: 0 !important;
      display: block;
      padding: 0 10px;
      padding-right: 15px;
      span {
        display: block;
        margin-left: 0;
      }
    }
  }
}

.button {
  background: rgba(90, 192, 236, 0.9) !important;
  /* fantasy */
  border: 2px solid #5ac0ec !important;
  width: fit-content;
  height: 40px;
  min-height: 40px;
  margin-left: 10px;
  font-family: Rift-Bold;
  white-space: nowrap;
  @media (max-width: 720px) {
    margin-left: 0;
  }
}
