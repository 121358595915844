@import "./_colors.scss";

@mixin twire-scrollbar {
  &::-webkit-scrollbar {
    padding: 0 5px;
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: #202229;
    padding: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &::-webkit-scrollbar-corner {
    background-color: #202229;
  }
}

@mixin rank-styles($background, $color) {
  $corner-dim: 3px;
  background: $background, #202229;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-left: 2px solid transparent;
    border-top: 2px solid transparent;
    height: 10px;
    width: 10px;
    z-index: 5;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-right: 2px solid transparent;
    border-bottom: 2px solid transparent;
    height: 24px;
    width: 10px;
    z-index: 5;
  }
  &::after {
    border-right: $corner-dim solid $color;
    border-bottom: $corner-dim solid $color;
  }
  &::before {
    border-left: $corner-dim solid $color;
    border-top: $corner-dim solid $color;
  }
}

$rank-colors: () !default;

$rank-colors: map-merge(
  (
    orange: map-get($colors, orange),
    blue: map-get($colors, blue),
    red: map-get($colors, red),
  ),
  $rank-colors
);

@mixin rank-tile($key, $color) {
  .rank-tile-#{$key} {
    @if $color == map-get($rank-colors, orange) {
      @include rank-styles(
        linear-gradient(
          180deg,
          rgba(41, 42, 47, 0) 50%,
          rgba(240, 171, 46, 0.1) 100%
        ),
        $color
      );
    }
    @if $color == map-get($rank-colors, blue) {
      @include rank-styles(
        linear-gradient(
          180deg,
          rgba(41, 42, 47, 0) 50%,
          rgba(90, 192, 236, 0.1) 100%
        ),
        $color
      );
    }
    @if $color == map-get($rank-colors, red) {
      @include rank-styles(
        linear-gradient(
          180deg,
          rgba(41, 42, 47, 0) 50%,
          rgba(236, 118, 81, 0.1) 100%
        ),
        $color
      );
    }
  }

  .reverse-rank-tile-#{$key} {
    @if $color == map-get($rank-colors, orange) {
      @include rank-styles(
        linear-gradient(
          180deg,
          rgba(240, 171, 46, 0.1)  -20%,
          rgba(41, 42, 47, 0) 100%
        ),
        $color
      );
    }
  }
}


@each $key, $value in $rank-colors {
  @include rank-tile($key, $value);
}
