@import "../../../../../../../styles/_mixins.scss";

.arrows {
  margin-left: 5px;
  display: flex;
  opacity: 0;
  flex-direction: column;
  position: absolute;
  right: -12px;
  svg {
    height: 6px;
    width: 6px;
  }
  .top {
    transform: rotate(180deg);
    margin-bottom: 4px;
  }
}

.cell {
  height: 40px;
  text-align: center;
  color: var(--light);
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  white-space: nowrap;
  vertical-align: middle;
}

.bigCell {
  max-width: 240px !important;
  min-width: 240px;
  text-overflow: ellipsis;
  position: sticky;
  left: 0;
  overflow: hidden;
  span {
    padding-right: 15px;
  }
  // display: inline-block;
  @media (max-width: 400px) {
    // width: 150px;
    min-width: 180px;
    max-width: 180px;
  }
  z-index: 2;
}

.container {
  position: relative;
  overflow-x: scroll;
  @include twire-scrollbar;
  // height: calc(100vh - 515px - 2vw);
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.table {
  position: absolute;
  top: 0;
  bottom: 0;
  border-collapse: collapse;
  td {
    padding: 0 !important;
  }
}

.logo {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.header {
  position: sticky;
  font-weight: bold;
  top: 0;
  z-index: 3;
}

.rightColHead {
  padding: 0px;
  div {
    position: relative;
  }
}

.content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.fade {
  opacity: 0.5;
}

.visible {
  opacity: 1;
}

.firstCell {
  justify-content: flex-start;
  padding-left: 1.75rem;
}

.firstHeaderArrow {
  margin-left: 0;
  transform: translateX(-5px);
}

.resizer {
  position: absolute;
  top: -10px;
  left: 2px;
  z-index: 4;
}
