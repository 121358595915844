.circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.container {
  position: relative;
  width: fit-content;
  height: fit-content;
  align-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.percentage {
  height: fit-content;
  width: fit-content;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
