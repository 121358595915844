@import "../../../../../../../styles/breakpoints";

.wrapper {
  max-width: inherit;
  overflow-x: auto;
}

.container {
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 4px;
  min-width: 640px;
  width: 100%;
  tr {
    font-weight: bold;
    font-size: 16px;
    & > :first-child {
      padding-left: 30px;
    }
    // & > :last-child {
    //   padding-right: 10px;
    // }
  }
  th {
    color: var(--orange);
  }
  td {
    color: var(--light);
    vertical-align: middle;
    img {
      display: inline-block;
      height: 18px;
      width: 18px;
    }
  }
  .header {
    background-color: var(--dark-ash);
    height: 40px;
    & > * {
      color: var(--light);
    }
  }
  .row {
    background-color: rgba(52, 55, 65, 0.4);
    height: 40px;
    & > :nth-child(1) {
      color: var(--orange);
    }
    & > :nth-child(2) {
      color: var(--orange);
    }
    & > :nth-child(3) {
      color: var(--orange);
    }
  }
}
/////////////////////////////////////
.name {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  display: flex;
  align-items: center;
  color: var(--light);
  margin-left: 40px;
  @media (max-width: mq(tournamentInfo, mobile)) {
    font-size: 28px;
    margin-left: 20px;
    line-height: 34px;
  }
  @media (max-width: 440px) {
    max-width: 200px;
    font-size: 18px;
    line-height: 24px;
    white-space: normal;
  }
}

// .container {
//   display: flex;
//   width: 100%;
// }

.socials {
  display: flex;
  align-items: center;
  & > * {
    position: relative;
  }
  & > :not(:first-child) {
    margin-left: 20px;
    @media (max-width: mq(tournamentInfo, mobile)) {
      margin-left: 8px;
    }
  }
  opacity: 0.7;
}

.prizeDetails {
  @media (max-width: mq(tournamentInfo, mobile)) {
    flex-direction: column;
    align-items: flex-start !important;
    & > * {
      margin: 0 !important;
      margin-bottom: 8px !important;
    }
  }
}

.lowerTable {
  transform: translateY(-5px);
}

.row {
  td {
    width: calc(100% / 3);
  }
}

.fourCols {
  td {
    width: calc(100% / 4);
  }
}

.teamName {
  color: var(--light) !important;
}
