@import "../../../styles/mixins";

$padding-big: 10px;
$padding-small: 4px;

.w200 {
  width: 200px;
}

.h40 {
  height: 40px;
}

.pBig {
  padding: $padding-big;
}

.pTotal {
  padding: calc(#{$padding-big + $padding-small});
}

.pSmall {
  padding: $padding-small;
}

.z5 {
  z-index: 5;
}

.lightBlack {
  background-color: rgba(0, 0, 0, 0.2);
}

.mainList {
  & > ul {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding-bottom: 4px;
    padding-top: 4px;
  }
  & > :last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.dropdown {
  max-height: 300px;
  overflow-y: auto;
  @include twire-scrollbar;
}

.arrow {
  min-width: 10px;
}
