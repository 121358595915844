.container {
  padding: 16px;
  background: var(--menu-grey);
  margin-top: 11px;
  .clear {
    text-decoration: underline;
    color: var(--orange);
    white-space: nowrap;
    font-weight: bold;
    font-size: 15px;
  }
  .apply {
    background: var(--orange);
    width: 163px;
    font-weight: bold;
    font-family: rift-bold;
    font-size: 14px;
    height: 40px;
    margin-left: 21px;
    color: var(--dark);
  }
  .filterActions {
    display: flex;
    align-items: center;
    padding-top: 19px;
  }
}
