$circleDim: 6px;
$h: 1px;
.container {
  height: 14px;
  width: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  div {
    height: $h;
    width: 100%;
    background-color: var(--orange);
    position: relative;
    border-radius: 10px;
    &::after {
      position: absolute;
      content: "";
      display: block;
      height: $circleDim;
      width: $circleDim;
      transform: translateY(-3px);
      border: 2px solid var(--orange);
      border-radius: 2px;
      background-color: var(--dark);
      transition: 0.2s;
    }
  }
  & > :first-child {
    &::after {
      left: 70%;
    }
  }
  & > :nth-child(2) {
    &::after {
      left: 35%;
    }
  }
  & > :last-child {
    &::after {
      left: -5%;
    }
  }
  // &:hover {
  //   & > :first-child {
  //     &::after {
  //       left: -5%;
  //     }
  //   }
  //   & > :nth-child(2) {
  //     &::after {
  //       left: 35%;
  //     }
  //   }
  //   & > :last-child {
  //     &::after {
  //       left: 70%;
  //     }
  //   }
  // }
}
