.resultsGrid {
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: calc(25% - 40px);
    max-width: calc(25% - 10px);
    @media (max-width: 1100px) {
      flex: unset;
      min-width: 100%;
    }
  }
  //   grid-template-columns: 1fr 1fr;
  gap: 10px;
}
