.container {
  border: 2px dashed #31343d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.svgWrapper {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #f0452e;
  svg {
    height: 20px;
    width: 20px;
    path {
      fill: #f0452e;
    }
  }
}

.errorMessage {
  font-weight: bold;
  font-size: 24px;
  color: var(--light);
  margin-top: 20px;
  margin-bottom: 5px;
  text-align: center;
}

.suggestion {
  opacity: 0.7;
  font-family: Open-Sans;
  color: var(--light);
  font-size: 12px;
  text-align: center;
}

.actionButton {
  color: var(--orange);
  text-decoration: underline;
  border: none;
  margin-top: 15px;
}
