@import "../../../styles/mixins";

$scrollBarHeight: 8px;
$scrollBarSpacing: 5px;

.shade {
  background: linear-gradient(
    270deg,
    rgba(20, 21, 24, 0.9) -15.25%,
    rgba(7, 7, 8, 0) 80.01%
  );
  z-index: 4;
  height: 100%;
  width: 20%;
  margin-left: auto;
  position: absolute;
  right: 0;
  top: 0;
  left: initial;
  pointer-events: none;
  transition: 0.3s;
  opacity: 1;
  display: none;
}

.hide {
  opacity: 0;
}

.scrollBar {
  height: $scrollBarHeight;
  margin-bottom: $scrollBarSpacing;
  position: absolute;
  max-width: 100%;
  transform: translateY(-12px);
  @include twire-scrollbar;
  overflow-x: auto;
}

.emptyTable {
  height: 1px;
}
