.container {
  width: 100%;
  & > div {
    background: white;
    opacity: 0.1;
  }
  .pos {
    width: 16px;
    height: 25px;
    margin-right: 20px;
  }
  .logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .desc {
    height: 25px;
    width: 100%;
  }
}
