@import "../../../../../../../../styles/_mixins.scss";

.placeholder {
  background-color: var(--placeholder-color);
  width: 80%;
  height: 55%;
}

.container {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  // max-height: calc(100vh - 515px - 2vw);
  @include twire-scrollbar;
}

.bigCell {
  max-width: 240px !important;
  min-width: 240px;
  text-overflow: ellipsis;
  // max-width:;
  overflow: hidden;
  @media (max-width: 400px) {
    // width: 150px;
    min-width: 180px;
  }
}

.cell {
  height: 40px;
  text-align: center;
  color: var(--light);
  font-weight: bold;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  white-space: nowrap;
}

.outerInner {
  position: relative;
  height: 100%;
  width: 100%;
}

.innerTable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  overflow-x: auto;
  grid-auto-flow: column;
  @include twire-scrollbar;
  padding: 0px 0px 4px 0px;
  overflow-y: hidden;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.start {
  justify-content: flex-start !important;
}

.arrows {
  margin-left: 5px;
  display: flex;
  opacity: 0;
  flex-direction: column;
  svg {
    height: 6px;
    width: 6px;
  }
  .top {
    transform: rotate(180deg);
    margin-bottom: 4px;
  }
}

.fade {
  opacity: 0.5;
}

.visible {
  opacity: 1;
}
