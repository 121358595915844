@import "../../../../styles/breakpoints";

.iconLink {
  height: fit-content;
  img {
    height: 30px;
    width: 152px;
  }
}

.hamburger {
  display: none;
  width: 23px;
  height: 19.96px;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  & > * {
    height: 3px;
    background-color: var(--orange);
    width: 100%;
    border-radius: 50px;
  }
  & > :nth-child(2) {
    width: 60%;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  @media (max-width: 1060px) {
    padding: 0 20px !important;
  }
  @media (max-width: mq(layout, mobile)) {
    & > :not(.iconLink) {
      display: none;
    }
    .hamburger {
      display: flex;
    }
  }
  @media (max-width: mq(featured, mobile)) {
    background-color: #202229;
  }
}

.partition {
  min-height: 30px;
  border-left: 1px solid var(--light-grey);
  border-right: 1px solid var(--light-grey);
  background-color: var(--light-grey);
  border-top: none;
  width: 0px;
  margin: 2vw;
  opacity: 0.2;
}

.button {
  height: 50px;
  width: 100px;
  border: 2px solid;
  font-weight: bolder;
  white-space: nowrap;
}

.logo {
  min-width: 142px;
  height: 30px;
}

.gear {
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin: 0 20px;
  svg {
    path {
      opacity: 0.7;
      fill: var(--light);
    }
  }
}
