@import "../../../../../../../styles/_mixins.scss";

.box {
  padding: 20px 10px;
  // max-width: 350px;
  // height: 302px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  // min-width: 300px;
  [class="UnopdropDown_EMFQP"] {
    width: 100%;
    z-index: 4;
  }
}

.dropdown {
  width: 200px;
  //   height: 200px;
  background-color: var(--ash);
  margin: 0;
  max-height: 220px;
  overflow: auto;
  @include twire-scrollbar;
}

.caption {
  font-size: 42px;
  text-align: center;
  margin-top: 50px;
}

.smaller {
  font-size: 24px;
  line-height: 0;
  margin-left: 5px;
  // display: inline-block;
  // overflow: hidden;
}

.bigger {
  line-height: 0;
  //   max-height: 45px;
  // display: inline-block;
  // overflow: hidden;
}

.playerName {
  width: fit-content;
  margin: 0 auto;
  font-size: 24px;
  font-family: Rift-Bold;
  color: var(--light);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  // display: none;
  .arrowTip {
    margin-left: 8px;
    path {
      fill: var(--light);
    }
  }
}

.searchBox {
  display: flex;
  background-color: var(--ash);
  padding: 5px 8px;
  box-sizing: content-box;
  height: 36px;
  align-items: center;
  position: sticky;
  top: 0;
  // height: 36px;
}
.searchSvgBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  background: rgba(0, 0, 0, 0.2);
  svg {
    height: 20px;
  }
}
.predictions {
  padding: 8px;
  padding-top: 0;
}

.search {
  width: 100%;
  background-color: transparent;
  padding-left: 10px;
  font-family: open-sans;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  height: 100%;
  outline: none;
  background: rgba(0, 0, 0, 0.2);
}

.button {
  font-size: 14px;
  font-family: open-sans;
  display: block;
  height: 36px;
  width: 100%;
  margin: 4px 0px;
  text-align: left;
  padding-left: 8px;
  color: rgba(255, 255, 255, 0.5);
}

.active {
  background: rgba(0, 0, 0, 0.2);
  color: var(--light);
}

.teamName {
  margin-bottom: 15px;
  margin-top: 5px;
}
