@import "../../../../styles/breakpoints";

.footerContainer {
  height: 185px;
  @media (max-width: mq(layout, mobile)) {
    height: fit-content;
  }
}

.footer {
  width: calc(100% - 2px);
  padding: 0 4vw;
  display: flex;
  align-items: center;
  @media (max-width: mq(layout, mobile)) {
    display: none;
  }
  font-size: 12px !important;
}

.mobileFooter {
  width: 100%;
  padding: 25px 20px;
  display: none;
  @media (max-width: mq(layout, mobile)) {
    display: block;
  }
  & > :nth-child(1) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
    margin-bottom: 10px;
    img {
      height: 22px;
      width: 115px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: var(--light);
    }
  }
}

.logo {
  height: 30px;
  width: 152px;
}

.footerText {
  opacity: 0.7;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 20px;
  font-family: open-Sans;
}

.footerLinks {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: right;
  width: fit-content;
  margin-left: auto;
  li {
    color: var(--light);
    margin: 0 15px;
  }
  & > :first-child {
    margin-left: 0;
  }
  & > :last-child {
    margin-right: 0;
  }
}

.socials {
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  svg {
    height: 24px;
    width: 24px;
    opacity: 0.7;
  }
}

.joinUs {
  margin-left: auto;
  margin-right: 50px;
  margin-left: 50px;
  white-space: nowrap;
  font-size: 12px;
  color: var(--light);
}

.socialsWrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.disclaimer {
  display: flex;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.2);
  & > :first-child {
    max-width: 183px;
    font-family: Open-Sans;
  }
  & > :last-child {
    margin-left: auto;
    font-family: Open-Sans;
  }
}
