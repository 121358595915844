.container {
  width: 28px;
  height: 16px;
  background: rgba(255, 255, 255, 0.2);
  position: relative;
  padding: 2px;
  transition: 0.3s;
  & > div {
    width: 12px;
    height: 12px;
    background: var(--grey);
    border-radius: 1px;
    transition: 0.3s;
  }
}

.active {
  background-color: var(--orange);
  & > div {
    margin-left: 50%;
  }
}
