.container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  .planWrapper {
    max-width: 380px;
    width: 100%;
    z-index: 1;
  }
}
.header {
  font-size: 42px;
  color: var(--light);
  font-family: Rift-Bold;
}

.wrapper {
  height: calc(100vh - 80px);
}

.pHolder1,
.pHolder2 {
  width: 15vw;
  background-color: var(--orange);
  opacity: 0.7;
  min-width: 200px;
  @media (max-width: 800px) {
    display: none;
  }
}

.pHolder1 {
  transform: translateY(15%) translateX(10%);
}

.pHolder2 {
  transform: translateY(15%) translateX(-10%);
}

.loader {
  margin-top: 100px;
}
