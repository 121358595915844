@import "../../../styles/breakpoints";

.ads {
  margin-top: 22px;
  padding: 0px 2vw 0px 2vw;

  @media (max-width: mq(layout, mobile)) {
    margin-bottom: 22px;
  }
}

.banner {
  margin: 22px 2vw 0px 2vw;

  a img {
    margin: 0 auto;
  }

  .desktopBanner {
    @media (max-width: mq(layout, mobile)) {
      display: none;
    }
  }
  
  .mobileBanner {
    @media (min-width: mq(layout, mobile)) {
      display: none;
    }
  }
}