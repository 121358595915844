@import "../../../styles/mixins";
@import "../../../styles/breakpoints";

.search {
  background-color: transparent;
  width: 100%;
  height: 100%;
  font-family: Open-Sans;
  font-size: 12px;
  color: var(--light);
  padding-right: 20px;
  &:focus {
    outline: none;
  }
}

.top {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  transition: 0.2s;
  @media (max-width: mq(dropdown, mobile)) {
    // padding-left: 0px;
    // padding-right: 0px;
    background-color: transparent !important;
  }
}

.list {
  width: 100%;
  min-width: 400px;
  max-height: 500px;
  background-color: var(--menu-grey);
  margin-top: 2px;
  padding: 20px;
  box-shadow: -1px 5px 7px 0px rgba(0, 0, 0, 0.25);
  @include twire-scrollbar;
  overflow-x: auto;
  @media (max-width: mq(layout, mobile)) {
    min-width: unset;
    margin-top: 10px;
  }
  & > * {
    margin-bottom: 20px;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}

.dropdownMenu {
  width: 100% !important;
}

.close {
  position: absolute;
  right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: none;
  svg {
    height: 21px;
    width: 21px;
  }
}
