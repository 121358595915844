@import "../../../styles/breakpoints";

.container {
  padding: 12px 30px;
  width: 100%;
  margin: 4px 0px;
  min-height: 72px;
  background-color: var(--grey);
  display: flex;
  align-items: center;
  min-width: fit-content;
  width: full;
  @media (max-width: mq(ranking, mobile)) {
    // flex-direction: column;
    // padding: 20px 20px;
  }
}

.progressWrapper {
  height: 50px;
  width: 50px;
 
}

.translateWrapper {
  transform: translateX(-5px);
}

.pos {
  font-weight: bolder;
  font-size: 20px;
  font-size: 20px;
  // min-width: 30px;
  display: flex;
  font-family: Rift-Bold;
}

.teamLogo {
  width: 30px;
  height: 30px;
  // margin-left: 20px;
  // margin-right: 10px;
  @media (max-width: mq(ranking, mobile)) {
    width: 25px;
    height: 25px;
  }
}

.first {
  display: grid;
  grid-template-columns: minmax(30px, max-content) minmax(30px, max-content) 200px auto auto;
  align-items: center;
  gap: 15px;
  @media (max-width: 768px) {
    gap: 25px;
    grid-template-columns: minmax(30px, max-content) minmax(30px, max-content) 100px auto auto;
  }
  width: 100%;
}

.team {
  font-size: 24px;
  color: var(--light);
  // margin-left: 20px;
  font-family: Rift-Bold;
  span {
    font-family: Rift-Bold;
  }
}

.ratingText {
  font-size: 32px;
  font-weight: bolder;
  font-family: Rift-Bold;
}

.arrowSpanWrapper {
  @media (max-width: mq(ranking, mobile)) {
    margin-left: auto;
  }
}

.diff {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  opacity: 1 !important;
}

.up {
  color: var(--green);
  svg {
    path {
      fill: var(--green);
    }
  }
}

.down {
  color: var(--red);
  svg {
    path {
      fill: var(--red);
    }
  }
}

.statsWrapper {
  gap: 20px;
  width: fit-content;
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(1fr, 5);
  // display: flex;
  // justify-content: flex-end;
  span, button {
    width: 100vw;
    max-width: 59px;
    display: block;
    font-family: Rift-Bold;
    color: white;
    font-size: 32px;
    @media (max-width: 1032px) {
      max-width: 40px;
    }
    @media (max-width: 768px) {
      // max-width: 30px;
    }
  }
}

// .progressWrapper {
//   margin-right: 10%;
// }
