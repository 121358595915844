@import "../../../../../../../styles/breakpoints";

.container {
  background-color: var(--grey);
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 314px;
  width: 100%;
  @media (max-width: 1366px) {
    padding: 30px 15px;
  }
}

.svgCircle {
  border: 1px solid var(--orange);
  min-height: 70px;
  min-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  svg {
    height: 24px;
    width: 24px;
    path {
      fill: var(--orange);
    }
  }
}

.text {
  font-size: 100px;
  line-height: 1;
}
