.container {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 20px;
}

.replyActions {
  display: flex;
  margin-top: 5px;
  & > * {
    max-width: 67px;
    min-height: 40px;
    max-height: 40px;
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(32, 34, 41, 0.9);
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  font-family: rift-bold;
  color: var(--light);
}
