@import "../../../../../../../../styles/breakpoints";

$width: 217px;
$height: 40px;

.dropdownWrapper {
  display: flex;
  width: 100%;
  @media (max-width: mq(tournamentFilter, small)) {
    & > * {
      margin: 0 auto 20px auto;
    }
  }
}

.trigger {
  width: 100%;
  max-width: $width;
  min-width: $width;
  height: $height;
  background: var(--dark-ash);
  opacity: 0.9;
  color: var(--orange);
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 10px;
  }
}

.groups {
  margin-top: 4px;
  display: flex;
  gap: 3px 3px;
  flex-wrap: wrap;
  & > button {
    width: 100%;
    max-width: $width;
    min-width: $width;
    height: $height;
    background: var(--grey);
    color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    font-size: 12px;
    @media (max-width: mq(tournamentFilter, small)) {
      max-width: unset !important;
      flex-grow: 1;
      flex-basis: 0;
      min-width: fit-content;
      padding: 0 20px;
      margin-bottom: 5px;
    }
  }
  .active {
    border-bottom: 2px solid var(--orange);
    color: var(--orange);
  }
}

.dropdown {
  width: $width;
  padding: 10px;
  background-color: var(--menu-grey);
  margin-top: 2px;
  & > button {
    width: 100%;
    height: $height;
    // background-color: #1b1c22;
    margin: 3px 0;
    color: var(--light);
    font-size: 12px;
    font-weight: bold;
  }
  .active {
    color: var(--orange);
    background-color: rgba(0, 0, 0, 0.2);
  }
}
