@import "../../../../../../styles/breakpoints";

$transition-duration: 0.3s;
@keyframes disappear {
  100% {
    opacity: 0;
    transform: scale(1.05);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.tournamentName {
  font-size: 50px;
  font-weight: bolder;
  font-family: rift-bold;
  margin: 0;
  margin-left: 30px;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 550px;
  line-height: 45px;
  @media (max-width: mq(featured, mobile)) {
    line-height: 40px;
    font-size: 40px;
    margin-left: 20px;
  }
}

.badge {
  height: 95px;
  width: 95px;
  @media (max-width: mq(featured, mobile)) {
    width: 75px;
    height: 75px;
  }
}

.details {
  display: flex;
  margin-top: 20px;
  & > :first-child {
    margin-left: 0;
  }
  & > div {
    margin: 0 20px;
    @media (max-width: mq(featured, mobile)) {
      margin: 0 12px;
    }
    & > :nth-child(1) {
      color: var(--orange);
      font-size: 16px;
      font-weight: bold;
      @media (max-width: mq(featured, mobile)) {
        font-size: 12px;
      }
    }
    & > :nth-child(2) {
      font-size: 24px;
      color: var(--light);
      font-weight: bold;
      @media (max-width: mq(featured, mobile)) {
        font-size: 18px;
      }
    }
  }
}

.button {
  padding: 0px 30px;
  font-weight: bold;
  font-family: rift-bold;
  border: 2px solid var(--orange);
  height: 50px;
  white-space: nowrap;
}

.play {
  display: flex;
  margin-left: 20px;
  a {
    margin-left: 10px;
    color: var(--orange);
    font-weight: bold;
    text-decoration: underline;
    font-size: 16px;
    white-space: nowrap;
  }
}

.charImg {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2 !important;
  @media (max-width: mq(featured, mobile)) {
    display: none;
  }
}

.container {
  height: 100%;
  width: 100%;
  @media (max-width: mq(featured, mobile)) {
    // background-color: #202229;
    background-color: transparent;
  }
}

.tournamentContent {
  padding: 30px;
  @media (max-width: mq(featured, mobile)) {
    padding: 50px 20px;
  }
}

.disappearing {
  .animatable {
    animation: disappear $transition-duration forwards;
  }
}

.appearing {
  .animatable {
    animation: appear $transition-duration;
  }
}
