.withoutSvg {
  .input {
    background: rgba(52, 55, 65, 0.2);
    border: 2px solid #343741;
    padding: 15px;
    width: 100%;
    transition: border 0.3s;
    font-family: Open-Sans;
    font-size: 14px;
    &::placeholder {
      font-family: Open-Sans;
    }
    &:focus {
      outline: none;
      border: 2px solid var(--orange);
    }
    &.blue:focus {
      outline: none;
      border: 2px solid var(--blue);
    }
  }

  .errorMessage {
    color: var(--red);
    font-size: 14px;
  }
}

.withSvg {
  .inputWrapper {
    height: 50px;
    background: rgba(52, 55, 65, 0.2);
    border: 2px solid #343741;
    padding: 8px 15px;
    width: 100%;
    transition: border 0.3s;
    font-family: Open-Sans;
    font-size: 14px;
    display: flex;
    align-items: center;
    &::placeholder {
      font-family: Open-Sans;
    }
    &:focus {
      outline: none;
      border: 2px solid var(--orange);
    }
    &.blue:focus {
      outline: none;
      border: 2px solid var(--blue);
    }
    &.blue {
      svg {
        path {
          fill: #5ac0ec;
        }
      }
    }
    input {
      font-family: Open-Sans;
      font-size: 14px;
      width: 100%;
      height: 100%;
      color: white;
      background-color: transparent !important;
      font-family: Open-Sans;
      text-overflow: ellipsis;
      outline: none;
      padding-right: 0px;
      margin-left: 12px;
      &:focus {
        background-color: transparent !important;
      }
      &:active {
        background-color: transparent !important;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
      background-color: transparent !important;
    }

    svg {
      width: 20px;
      height: 18px;
      path {
        fill: #5ac0ec;
      }
    }
  }

  .errorMessage {
    color: var(--red);
    font-size: 14px;
  }
}
