@import "../../../../../../styles/breakpoints";

.container {
  padding: 20px;
  max-width: 100%;
  background-color: var(--grey);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  @media (max-width: mq(team, matches, mobile)) {
    display: block;
  }
}

.logo {
  height: 60px;
  width: 60px;
  margin-right: 14px;
}

.actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 30px;
  }
  @media (max-width: mq(team, matches, tab)) {
    flex-direction: column;
    & > :first-child {
      margin-right: 0;
      margin-left: auto;
      margin-bottom: 9px;
    }
  }
  @media (max-width: mq(team, matches, mobile)) {
    flex-direction: row;
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding-top: 20px;
    & > :first-child {
      margin-right: auto;
      margin-left: 0;
      margin-bottom: 0px;
    }
  }
}

.actionIcon {
  height: 20px;
  width: 20px;
}

.placeholder {
  height: 100px;
}

.placeholderTournamentName {
  height: 20px;
  width: 80%;
  background-color: var(--placeholder-color);
}

.placeholderMatchName {
  margin-top: 10px;
  height: 14px;
  background-color: var(--placeholder-color);
  width: 35%;
}

.placeholderLogo {
  background-color: var(--placeholder-color);
}

.arrowSpanPlaceholder {
  width: 41.41px;
  height: 23.77px;
  background-color: var(--placeholder-color);
}
