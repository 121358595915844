.container {
  background: linear-gradient(
    180deg,
    rgba(32, 34, 41, 0.4) 0%,
    #202229 100%
  ) !important;
}

.headline {
  background: #ffffff;
  opacity: 0.1;
  height: 25px;
  width: 100%;
}

.caption {
  background: #ffffff;
  opacity: 0.1;
  height: 20px;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.base {
  margin-top: 0;
}

.left {
  display: inline-block;
  width: 49px;
  height: 18px;
  background: #ffffff;
  opacity: 0.1;
}

.right {
  background: #ffffff;
  opacity: 0.1;
  width: 73px;
  height: 18px;
}

.short {
  .left,
  .right {
    display: none;
  }
}
