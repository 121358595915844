@import "../../../../../../styles/breakpoints";

.container {
  display: grid;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: mq(team, statistics, cards, big-tab)) {
    display: flex;
    justify-content: space-evenly;
  }
  @media (max-width: mq(team, statistics, cards, mobile)) {
    display: flex;
    align-items: center;
  }
}
