@import "../../../../styles/breakpoints";

.container {
  display: flex;
  width: 100%;
  @media (max-width: mq(team, tab)) {
    flex-direction: column;
  }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}
.left {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.right {
  margin-left: 20px;
  max-width: 280px;
  min-width: 280px;
  @media (max-width: mq(team, tab)) {
    margin-top: 20px;
    max-width: unset;
    margin-left: 0;
  }
}

.error {
  margin: 30px 20px;
  height: 70vh;
}
