@import "../../../../styles/breakpoints";

.container {
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: mq(featured, mobile)) {
    width: calc(100% + 4vw);
    margin-left: -2vw;
  }
}

.line {
  width: 100%;
  height: 2px;
  background-color: var(--orange);
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.errorComponent {
  height: 306px;
  width: 100%;
}
