@import "../../../../../../styles/breakpoints";

@keyframes beacon {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.badge {
  width: 75px;
  height: 75px;
  @media (max-width: mq(tournamentInfo, mobile)) {
    font-size: 28px;
    width: 59.04px;
    height: 59.04px;
  }
}

.name {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  display: flex;
  align-items: center;
  color: var(--light);
  margin-left: 40px;
  padding-right: 10px;
  @media (max-width: mq(tournamentInfo, mobile)) {
    font-size: 28px;
    margin-left: 20px;
    line-height: 34px;
  }
  @media (max-width: 440px) {
    max-width: 200px;
    font-size: 18px;
    line-height: 24px;
    white-space: normal;
  }
}

.container {
  display: flex;
  width: 100%;
}

.socials {
  display: flex;
  align-items: center;
  margin-top: 20px;
  & > * {
    position: relative;
  }
  & > :not(:first-child) {
    margin-left: 20px;
    @media (max-width: mq(tournamentInfo, mobile)) {
      margin-left: 8px;
    }
  }
  opacity: 0.7;
}

.prizeDetails {
  @media (max-width: mq(tournamentInfo, mobile)) {
    flex-direction: column;
    align-items: flex-start !important;
    & > * {
      margin: 0 !important;
    }
  }
}

.live {
  &::before {
    position: absolute;
    content: "";
    top: -8px;
    right: -10px;
    background: #e75050;
    opacity: 0.2;
    width: 9px;
    height: 9px;
    animation: beacon 2s linear infinite;
    border-radius: 100%;
  }
  &::after {
    position: absolute;
    content: "";
    top: -6px;
    right: -8px;
    background: #e75050;
    width: 5px;
    height: 5px;
    border-radius: 100%;
  }
}

.info {
  & > :nth-child(1) {
    color: var(--orange);
  }
  & > :nth-child(2) {
    font-size: 24px;
    color: var(--light);
  }
}

.infoSection {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  @media (max-width: mq(tournamentInfo, mobile)) {
    & > * {
      width: 50%;
    }
  }
  & > :not(:last-child) {
    margin-right: 64px;
    @media (max-width: mq(tournamentInfo, mobile)) {
      margin-right: 0px;
    }
  }
}

.formatBox {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: mq(tournamentInfo, mobile)) {
    display: block;
  }
  & > :not(:last-child) {
    margin-right: 40px;
    @media (max-width: mq(tournamentInfo, mobile)) {
      margin-right: 0px;
    }
  }
}

.format {
  max-width: 200px;
  & > :first-child {
    color: var(--light);
    font-size: 24px;
    font-weight: bold;
  }
  & > :not(:first-child) {
    color: var(--light);
    opacity: 0.7;
    font-family: Open-Sans;
    font-size: 12px;
    margin-bottom: 8px;
  }
}

.prize {
  font-size: 24px;
  margin-left: 64px;
}

.trigger {
  margin-left: auto;
  height: 40px;
  min-width: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lowerTable {
  transform: translateY(-50px);
}

.left {
  width: 100%;
}
