.arrowSpan {
  height: 19px;
  color: var(--orange);
  text-decoration: underline;
  display: flex;
  align-items: center;
  span {
    margin-right: 3px;
  }
}
