//
// Color system
//
$colors: () !default;
$colors: map-merge(
  (
    light-grey: #fffbfb,
    grey: #202229,
    ash: #33353d,
    light-ash: rgba(52, 55, 65, 0.9),
    dark-ash: #343741,
    dark: #18191d,
    light: #ffffff,
    orange: #f0ab2e,
    dark-orange: rgba(240, 171, 46, 0.9),
    darkest-orange: rgba(240, 171, 46, 0.1),
    menu-grey: #32353f,
    tooltip-dark: #18191d,
    blue: #5ac0ec,
    light-blue: rgba(90, 192, 236, 0.4),
    dark-blue: #0b739f,
    maroon: #ec7651,
    red: #f0452e,
    pink-red: #c2264c,
    pink-red-dark: rgba(194, 38, 76, 0.1),
    green: #48b145,
    opaque-white: rgba(255, 255, 255, 0.3),
    placeholder-color: rgba(255, 255, 255, 0.1),
    table-tile: #31343d,
  ),
  $colors
);

// texts mixin
@mixin text-twire($parent, $color) {
  #{$parent} {
    color: $color;
  }
  #{$parent}-i {
    color: $color !important;
  }
}

// backgrounds mixin
@mixin background-twire($parent, $color) {
  #{$parent} {
    background: $color;
  }
  #{$parent}-i {
    background: $color !important;
  }
}

// border-color mixin
@mixin border-twire($parent, $color) {
  #{$parent} {
    border-color: $color;
  }
  #{$parent}-i {
    border-color: $color !important;
  }
}

// text color classes mapping
@each $color, $value in $colors {
  @include text-twire(".text-twire-#{$color}", $value);
}

// background color classes mapping
@each $color, $value in $colors {
  @include background-twire(".background-twire-#{$color}", $value);
}

// border color classes mapping
@each $color, $value in $colors {
  @include border-twire(".border-twire-#{$color}", $value);
}

@mixin create-css-variable($key, $value) {
  #{--#{$key}}: $value;
}

// This creates css varaibles
:root {
  @each $color, $value in $colors {
    @include create-css-variable($color, $value);
  }
}

body {
  background-color: map-get($colors, dark);
}
