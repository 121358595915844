.up {
  svg {
    transform: rotate(180deg);
    path {
      fill: var(--green);
    }
  }
}

.down {
  svg {
    path {
      fill: var(--red);
    }
  }
}

.increment {
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
}
