.container {
  color: var(--light);
  width: 100%;
  overflow-x: auto;
  .row {
    height: 40px;
    min-width: fit-content;
    white-space: nowrap;
  }
  .wrapperGrid {
    display: grid;
    // grid-template-columns: max-content auto auto auto;
    width: 100%;
  }
  .grey1 {
    background: rgba(52, 55, 65, 0.9);
  }
  .grey2 {
    background: #31343d;
  }
  .grey3 {
    background: #202229;
  }
  .rank {
    width: 65px;
  }
  .p30 {
    padding-left: 30px;
  }
  .logo {
    height: 18px;
    width: 18px;
    margin-right: 7px;
  }
  .team {
    width: 300px;
    @media (max-width: 400px) {
      // width: 150px;
      width: 180px;
    }
  }
  .wins {
    width: 100%;
  }
  .damage {
    width: 100%;
  }
  .kills {
    width: 100%;
  }
  .points {
    width: 100%;
  }
  .actionsRow {
    display: flex;
    padding: 0 20px;
    & > :nth-child(2) {
      margin: 0 10px;
    }
    button {
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}
