.heading {
  background-color: aliceblue;
}

.fullPageOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10000000000;
  background-color: var(--dark);
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: var(--orange);
    font-family: Open-Sans;
    margin-top: 10px;
  }
}

.hide {
  opacity: 0;
}

@keyframes pop-up {
  0% {
    transform: translate(-50%, -60%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.tooltip {
  position: absolute;
  display: flex;
  z-index: 10000;
  background: #202229;
  padding: 10px;
  border: 1px solid rgba(52, 55, 65, 0.9);
  font-size: 10px;
  font-family: open-sans;
  color: var(--light);
  transform: translate(-50%, -50%);
  animation: pop-up 0.3s forwards;
  pointer-events: none;
  & > * {
    font-family: open-sans;
  }
  &::after {
    pointer-events: none;
    content: "";
    height: 7px;
    width: 7px;
    background: #202229;
    border-right: 1px solid rgba(52, 55, 65, 0.9);
    border-bottom: 1px solid rgba(52, 55, 65, 0.9);
    position: absolute;
    display: block;
    left: 50%;
    top: calc(100% + 1px);
    bottom: 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
}

.message {
  @media (max-width: 550px) {
    display: block !important;
    button {
      margin-top: 10px;
    }
  }
}
