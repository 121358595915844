.container {
  padding: 16px 14px;
  display: flex;
  align-items: center;
}

.logo {
  width: 42px;
  height: 42px;
  margin-right: 8px;
}
