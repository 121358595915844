@import "../../../../../../styles/breakpoints";

.statsRow {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
  display: flex;
  & > * {
    flex: 1 1 0;
    width: 0;
  }
  @media (max-width: mq(playerStats, tab)) {
    flex-wrap: wrap;
    & > * {
      flex: 1 1 50%;
      width: 0;
    }
  }
  @media (max-width: mq(playerStats, mobile)) {
    flex-direction: column;
    & > * {
      width: 100%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
    }
  }
}

.placeholder {
  display: flex;
  margin-bottom: 1.25rem;
  & > div {
    height: 357.66px;
    flex: 1 1 0;
    background-color: var(--placeholder-color);
  }
  @media (max-width: mq(playerStats, tab)) {
    flex-wrap: wrap;
    & > div {
      flex: 1 1 calc(50% - 10px);
      width: 0;
      margin: 0 !important;
      margin-bottom: 10px !important;
    }
    & > :nth-child(even) {
      margin-left: 10px !important;
    }
  }
  @media (max-width: mq(playerStats, mobile)) {
    flex-direction: column;
    align-items: center;
    & > div {
      // max-width: 265.55px;
      width: 100%;
      height: 357.66px;
      flex: unset;
      margin: 0 !important;
      margin-bottom: 1.25rem !important;
    }
    & > :nth-child(even) {
      margin-left: unset !important;
    }
  }
}
