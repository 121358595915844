.container {
  padding: 20px;
  background-color: var(--grey);
  color: var(--light);
  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
  & > :nth-child(1) {
    padding-bottom: 10px;
    img {
      width: 60px;
      height: 60px;
    }
  }
}

.tournamentName {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.desc {
  margin-top: 10px;
  display: flex;
  align-items: center;
  p {
    margin: 0;
    opacity: 0.7;
    font-family: Open-Sans;
    font-weight: normal;
    font-size: 12px;
  }
}
