@import "../../../styles/mixins";

.container {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  min-height: 60px;
  background-color: var(--grey);
  img {
    margin-right: 10px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
  }
}

.index {
  color: rgba(255, 255, 255, 0.3);
  margin-right: 20px;
}

// $corner-dim: 3px;

// .orange {
//   background: linear-gradient(
//       180deg,
//       rgba(41, 42, 47, 0) 50%,
//       rgba(240, 171, 46, 0.1) 100%
//     ),
//     #202229;
//   &::after {
//     border-right: $corner-dim solid var(--orange);
//     border-bottom: $corner-dim solid var(--orange);
//   }
//   &::before {
//     border-left: $corner-dim solid var(--orange);
//     border-top: $corner-dim solid var(--orange);
//   }
// }

// .blue {
//   background: linear-gradient(
//       180deg,
//       rgba(41, 42, 47, 0) 50%,
//       rgba(90, 192, 236, 0.1) 100%
//     ),
//     #202229;
//   &::after {
//     border-right: $corner-dim solid var(--blue);
//     border-bottom: $corner-dim solid var(--blue);
//   }
//   &::before {
//     border-left: $corner-dim solid var(--blue);
//     border-top: $corner-dim solid var(--blue);
//   }
// }

// .red {
//   background: linear-gradient(
//       180deg,
//       rgba(41, 42, 47, 0) 50%,
//       rgba(236, 118, 81, 0.1) 100%
//     ),
//     #202229;
//   &::after {
//     border-right: $corner-dim solid var(--red);
//     border-bottom: $corner-dim solid var(--red);
//   }
//   &::before {
//     border-left: $corner-dim solid var(--red);
//     border-top: $corner-dim solid var(--red);
//   }
// }

.teamImage {
  height: 30px;
  width: 30px;
}

.up {
  color: #48b145 !important;
  svg {
    transform: rotate(180deg);
    path {
      fill: #48b145;
    }
  }
}

.down {
  color: #f0452e !important;
  svg {
    path {
      fill: #f0452e;
    }
  }
}

.rankChange {
  svg {
    margin-right: 5px;
    margin-left: 10px;
  }
}
