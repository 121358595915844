@import "../../../../../styles/breakpoints";

@keyframes moveSlideshow {
  1% {
    opacity: 1;
  }
  100% {
    transform: translateX(-200%);
  }
}

.container {
  * {
    z-index: 3;
  }
  height: 100%;
  width: 100%;
}

.backgroundText {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  font-size: 300px;
  z-index: 1;
  display: flex;
  transition: 0.3s;
  // justify-content: center;
  align-items: center;
  white-space: nowrap;
  -webkit-text-stroke: 2px rgba(240, 171, 46, 0.1);
  color: transparent;
  background: linear-gradient(
      180deg,
      rgba(41, 42, 47, 0) 63.43%,
      rgba(236, 181, 81, 0.7) 137.71%,
      rgba(240, 171, 46, 0.7) 137.71%
    ),
    #202229;
  opacity: 0.5;
  @media (max-width: mq(featured, mobile)) {
    opacity: 1 !important;
    -webkit-text-stroke: transparent;
  }
}

.text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  font-size: 300px;
  z-index: 1;
  display: flex;
  transition: 0.3s;
  // justify-content: center;
  align-items: center;
  white-space: nowrap;
  -webkit-text-stroke: 2px rgba(240, 171, 46, 0.1);
  color: transparent;
  opacity: 0;
}

.moveText {
  animation: moveSlideshow 50s linear infinite;
}

.triangles {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.triangles {
  z-index: 0;
}
