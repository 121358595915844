.banner {
  margin-top: 20px;
  padding: 10px;
  // width: 100%;
  //   background: url("../../../assets/images/abstract/banner-background.png"),
  //     linear-gradient(
  //       180deg,
  //       rgba(41, 42, 47, 0) 63.43%,
  //       rgba(236, 181, 81, 0.7) 137.71%,
  //       rgba(240, 171, 46, 0.7) 137.71%
  //     ),
  //     #202229;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  color: var(--light);
  text-align: center;

  button {
    font-size: 17px;
  }
}

.info {
  background-image: url("../../../assets/images/abstract/banner-background.png");
  background-color: rgba(32, 34, 41, 0.5);
  border-bottom: 1px solid var(--orange);
}

.error {
  background-image: url("../../../assets/images/abstract/banner-background-white.png");
  background-color: var(--red);
  border-bottom: 1px solid var(--light);
}

.fantasy {
  background-image: url("../../../assets/images/abstract/blue-banner-background.png");
  background-color: rgba(32, 34, 41, 0.5);
  border-bottom: 2px solid var(--blue);

  button {
    background: rgba(90, 192, 236, 0.9) !important;
    border: 2px solid #5ac0ec !important;
  }
}
