.heading {
  font-size: 42px;
  color: var(--light);
  font-family: Rift-Bold;
  text-align: center;
}

.searchBox {
  background: #34374133;
  border: 2px solid var(--dark-ash);
  padding: 0 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  input {
    height: 85px;
    width: 100%;
    font-family: Open-Sans;
    color: var(--light);
    background-color: transparent;
    &:focus {
      outline: none;
    }
  }
}

.hr {
  opacity: 0.2;
  width: 100%;
  border: 1px solid #fffbfb;
  border-top: none;
}

.result {
  min-height: 500px;
}

.minSearchHeight {
  min-height: 50vh;
}
