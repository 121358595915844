$scale-diff: 0.2;

.container {
  height: 12px;
  width: 12px;
  position: relative;
  outline: none !important;
  transition: 0.3s;
  &:hover {
    transform: scale(1 + $scale-diff);
  }
  //   background-color: red;
}

.close {
  &:hover {
    transform: scale(1 - $scale-diff);
  }
}

.container > svg {
  height: 4.5px;
  width: 4.5px;
  position: absolute;
}

.container > :nth-child(1) {
  top: 0;
  left: 0;
}

.container > :nth-child(2) {
  top: 0;
  right: 0;
  transform: rotate(90deg);
}

.container > :nth-child(3) {
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.container > :nth-child(4) {
  bottom: 0;
  left: 0;
  transform: rotate(-90deg);
}

.close > :nth-child(1) {
  top: 0;
  left: 0;
  transform: rotate(180deg);
}

.close > :nth-child(2) {
  top: 0;
  right: 0;
  transform: rotate(-90deg);
}

.close > :nth-child(3) {
  bottom: 0;
  right: 0;
  transform: rotate(0deg);
}

.close > :nth-child(4) {
  bottom: 0;
  left: 0;
  transform: rotate(90deg);
}
