@import "../../../styles/mixins";

.container {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 60px;
  background-color: var(--grey);
  img {
    margin-right: 10px;
  }
  p {
    font-size: 24px;
    font-weight: bold;
    width: 200px;
  }
  background: linear-gradient(180deg, rgba(41, 42, 47, 0) 50%, rgba(90, 192, 236, 0.1) 100%), #202229;
  position: relative;
  border-bottom: 2px solid var(--blue);
}

.index {
  color: rgba(255, 255, 255, 0.3);
  margin-right: 20px;
}

.teamImage {
  height: 30px;
  width: 30px;
}

.colInner {
  width: 100%;
  @media (max-width: mq(home, small)) {
    margin-left: 0px;
    & > p {
      font-size: 18px;
    }
  }
}

.tournamentDetail {
  justify-content: space-between;
  font-size: 12px !important;
  margin-top: 10px;
  & > :not(:first-child) {
    margin-left: 20px;
  }
  svg {
    height: 20px;
    width: 20px;
    opacity: 0.3;
  }
  .lockDate {
    margin-right: auto;
    opacity: 0.7;
  }
  .playNow{
    margin-left: auto;
  }
  @media (max-width: mq(home, small)) {
    & > * {
      margin-left: 15px !important;
      span {
        white-space: nowrap;
        margin-left: 4px;
      }
    }
    & > :first-child {
      margin-left: 0 !important;
    }
  }

  .items-center {
    width: 50%;
  }
}

.blue {
  span {
    color: var(--blue);
  }

  path {
    fill: var(--blue);
  }

  svg {
    height: 10px;
    width: 11px;
    opacity: 1;
  }
}
