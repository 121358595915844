@import "../../../../../styles/breakpoints";

.container {
  display: flex;
  @media (max-width: mq(tournaments, tab)) {
    display: block !important;
  }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}

.right {
  max-width: 280px;
  margin-left: 20px;
  width: 100%;
  @media (max-width: mq(tournaments, tab)) {
    margin-left: 0;
    max-width: unset;
    margin-top: 20px;
  }
}

.left {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}
