.button {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.blue {
    background-color: rgba(90, 192, 236, 0.9);
    border: 2px solid #5ac0ec;
  }
  &.red {
    background-color: rgba(194, 38, 76, 0.1);
    border: 2px solid #C2264C;
    color: #FFFFFF;
  }
  .loadingSvg {
    height: 20px;
    width: 20px;
  }
}
