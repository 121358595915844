@import "../../../../../../../styles/mixins";

.wrapper {
  height: 100%;
  @include twire-scrollbar;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
  tr {
    height: 40px;
    color: var(--light);
  }
  thead {
    tr {
      background: #343741;
    }
  }
  // overflow-x: auto;
  .row {
    height: 40px;
  }
  .wrapperGrid {
    display: grid;
    grid-template-columns: max-content max-content auto auto auto;
    grid-auto-flow: column;
    width: 100%;
  }
  .grey1 {
    background: rgba(52, 55, 65, 1);
    z-index: 2;
  }
  .grey2 {
    background: #31343d;
  }
  .grey3 {
    background: #202229;
    color: rgba(255, 255, 255, 1);
  }
  // .rank {
  // width: 65px;
  // }
  .p30 {
    padding-left: 30px;
  }
  .logo {
    height: 18px;
    width: 18px;
    margin-right: 7px;
  }
  .team {
    width: 200px;
    @media (min-width: 768px) {
      width: 240px;
    }
    @media screen and (min-width: 1600px) and (max-width: 1650px) {
      max-width: 200px;
    }
  }
  .wins {
    width: 100%;
  }
  .damage {
    width: 100%;
  }
  .kills {
    width: 100%;
  }
  .points {
    width: 100%;
  }
}

.advance {
  border-left: 3px solid var(--green);
}

.teamName {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.teamNamePlaceholder {
  width: 200px !important;
  @media (max-width: 400px) {
    width: 100px !important;
  }
}

.stickyTop {
  position: sticky;
  top: 0;
  white-space: nowrap;
}

.desktop {
  gap: 4px;
  display: flex;
  // flex-wrap: wrap;
}

.tab {
  display: none;
}

.fade {
  opacity: 0.5 !important;
}

@media (max-width: 1600px) {
  .tab {
    display: block;
  }
  .desktop {
    display: none;
  }
}
