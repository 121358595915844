@import "../../../styles/breakpoints";

.container {
  @media (max-width: mq(home, small)) {
    padding: 10px 10px !important;
  }
}

.colInner {
  @media (max-width: mq(home, small)) {
    margin-left: 0px;
    & > p {
      font-size: 18px;
    }
  }
}
.tournamentImg {
  max-width: 80px;
  max-height: 60px;
  min-width: 60px;
  min-height: 60px;
  @media (max-width: mq(home, small)) {
    max-width: 67px !important;
    max-height: 50px !important;
    min-width: 50px;
    min-height: 50px;
  }
}

.tournamentDetail {
  font-size: 12px !important;
  opacity: 0.7;
  margin-top: 10px;
  & > :not(:first-child) {
    margin-left: 20px;
  }
  svg {
    height: 20px;
    width: 20px;
    opacity: 0.3;
  }
  @media (max-width: mq(home, small)) {
    & > * {
      margin-left: 15px !important;
      span {
        white-space: nowrap;
        margin-left: 4px;
      }
    }
    & > :first-child {
      margin-left: 0 !important;
    }
  }
}

.detailContent {
  @media (max-width: mq(home, tab)) {
    flex-direction: column;
    & > :nth-child(1) {
      margin-bottom: 10px;
    }
  }
  @media (max-width: mq(home, small)) {
    flex-direction: row;
    width: 100%;
    margin-left: auto;
    width: fit-content;
    margin-top: 8px;
    font-size: 13px !important;
    a > svg {
      height: 12px;
      width: 18px;
    }
    & > :nth-child(1) {
      margin-bottom: 0px;
    }
  }
}

.tileContent {
  @media (max-width: mq(home, small)) {
    flex-wrap: wrap;
    flex-direction: column;
    & > :first-child {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
