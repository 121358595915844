@import "../../../../styles/breakpoints";

$width: 250px;
$tab-width: 184px;
$left-padding: 40px;
$left-padding-tab: 30px;

.gameSelectTrigger {
  height: 80px;
  width: $width;
  padding-left: $left-padding;
  font-size: 12px !important;
  font-weight: bold;
  @media (max-width: mq(dropdown, mobile)) {
    width: $tab-width;
    padding-left: $left-padding-tab;
    padding-right: $left-padding-tab;
    text-align: left;
  }
  @media (max-width: mq(layout, mobile)) {
    height: 70px;
  }
}

.dropDown {
  width: $width;
  @media (max-width: mq(dropdown, mobile)) {
    width: $tab-width;
  }
}

.dropDownItem {
  padding-left: calc(#{$left-padding} - 4px) !important;
  font-size: 12px !important;
  height: 52px;
  svg {
    height: 80%;
    width: 62px;
    path {
      fill: var(--light);
    }
  }
  @media (max-width: mq(dropdown, mobile)) {
    padding-left: calc(#{$left-padding-tab} - 4px) !important;
  }
  // width: $width;
}

.acitveClassName {
  @media (max-width: mq(dropdown, mobile)) {
    padding-left: calc(#{$left-padding} - 15px) !important;
  }
  span {
    color: var(--orange);
  }
  svg {
    path {
      fill: var(--orange);
    }
  }
}

.triggerSvg {
  height: 80%;
  width: 62px;
  path {
    fill: var(--orange);
  }
}
