@import "../../../../styles/breakpoints";

.mainLeft {
  width: 100%;
  display: flex;
  @media (max-width: mq(home, mobile)) {
    display: block !important;
  }
}

.ml {
  width: 100%;
}

.mobileMain {
  display: none;
}

.mainSubLeft {
  width: 100%;
  padding-right: 20px;
  overflow-x: hidden;
  @media (max-width: mq(home, mobile)) {
    padding-right: 0px;
    & > :not(.mobileMain) {
      display: none;
    }
    .mobileMain {
      display: block;
      margin-bottom: 30px;
    }
  }
}

.mainSubRight {
  min-width: 280px;
  max-width: 280px;
  @media (max-width: mq(home, mobile)) {
    max-width: unset;
  }
}

.mainRight {
  margin-left: 20px;
  min-width: 280px;
  max-width: 280px;
  @media (max-width: mq(home, tab)) {
    display: none;
  }
}

.tabFantasyBanner {
  display: none;
  
  @media (max-width: mq(home, tab)) {
    display: flex;
  }

  @media (max-width: mq(home, mobile)) {
    display: none;
  }
}

.blue {
  span {
    color: var(--blue);
  }

  path {
    fill: var(--blue);
  }
}
