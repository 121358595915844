.textArea {
  border: 2px solid #343741;
  background: rgba(52, 55, 65, 0.2);
  min-height: 80px;
  width: 100%;
  padding: 15px 25px 5px 15px;
  color: var(--light);
  opacity: 0.5;
  font-family: Open-Sans;
  &::placeholder {
    font-family: Open-Sans;
  }
}
