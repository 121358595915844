@import "../../../styles/breakpoints";

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  // margin-top: 3px;
  @media (max-width: mq(layout, mobile)) {
    margin-top: 0px;
  }
  li {
    display: flex;
    align-items: center;
    // padding: 10px 0;
    color: var(--light);
    span {
      opacity: 0.7;
    }
  }
}

.active {
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  font-weight: bold;
  span {
    opacity: 1 !important;
  }
}

.trigger {
  display: flex;
  &:focus {
    outline: none;
  }
}
