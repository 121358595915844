@import "../../../../styles/breakpoints";

.container {
  display: flex;
  width: 100%;
  // @media (max-width: mq(playerRanking, tab)) {
  //   flex-direction: column;
  // }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}

.right {
  max-width: 280px;
  margin-left: 20px;
  width: 100%;
  @media (max-width: mq(playerRanking, tab)) {
    max-width: unset;
    margin-left: 0px;
  }
  @media (max-width: 1300px) {
    display: none;
  }
}

.left {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.heading {
  height: 40px;
  background: rgba(52, 55, 65, 0.9);
  color: white;
  font-weight: bold;
  padding: 0px 30px;
  @media (max-width: mq(playerRanking, mobile)) {
    padding: 0px 20px;
  }
}

.rank {
  width: 30px;
}

.team {
  margin-left: 30px;
}

.point {
  margin-left: auto;
  margin-right: 60px + 24px;
  @media (max-width: mq(playerRanking, mobile)) {
    margin-right: 10px + 24px;
  }
}

// .region {
//   margin-right: 130px;
//   @media (max-width: mq(playerRanking, mobile)) {
//     display: none;
//   }
// }

.top {
  color: var(--light);
  h2 {
    font-weight: bold;
    font-size: 24px;
  }
  p {
    opacity: 0.3;
    font-family: Open-Sans;
  }
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  h3 {
    font-size: 16px;
    color: var(--light);
  }
  p {
    font-size: 14px;
    opacity: 0.7;
    color: var(--light);
    font-family: Open-Sans;
  }
}

.rankedPlayerCard {
  min-width: 217px;
}



.statsRow {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  & > * {
    flex: 1 1 0;
    // width: 0;
    height: 350px;
  }
  @media (max-width: mq(playerStats, tab)) {
    flex-wrap: wrap;
    max-width: 700px;
    & > * {
      height: 450px;
      flex: 1 1 50%;
      min-width: 50%;
    }
  }
  @media (max-width: mq(playerStats, mobile)) {
    // flex-direction: column;
    max-width: 380px;
    & > * {
      min-width: 100%;
      width: 100%;
      height: 480px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15) !important;
    }
  }
}
