.placeholder {
  background-color: var(--placeholder-color);
  height: 50%;
  width: 80%;
}

.actionButton {
  height: 20px;
  width: 20px;
}
