@import "../../../../../../styles/breakpoints";
@import "../../../../../../styles/mixins";

$width: 217px;
$height: 40px;

@mixin tournamentFilter-width {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  @media (max-width: mq(tournamentFilter, small)) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (min-width: 1500px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media (min-width: 1700px) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.dropdownWrapper {
  display: grid;
  gap: 3px 3px;
  width: 100%;
  [class*="drop-down-menu"] {
    z-index: 15 !important;
  }
  @include tournamentFilter-width;
  // @media (min-width: 1500) {
  //   grid-template-columns: repeat(5, minmax(0, 1fr));
  // }
}

.trigger {
  width: 100%;
  // max-width: $width;
  // min-width: $width;
  padding: 10px;
  height: $height;
  background: var(--dark-ash);
  opacity: 0.9;
  color: var(--orange);
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 10px;
  }
}

.wFull {
  width: 100% !important;
}

.groups {
  margin-top: 4px;
  display: grid;
  // grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 3px 3px;
  flex-wrap: wrap;
  @include tournamentFilter-width;
  & > button {
    width: 100%;
    padding: 10px;
    // max-width: $width;
    // min-width: $width;
    height: $height;
    background: var(--grey);
    color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    font-size: 14px;
    @media (max-width: mq(tournamentFilter, small)) {
      // max-width: unset !important;
      flex-grow: 1;
      flex-basis: 0;
      // min-width: fit-content;
      padding: 0 20px;
      // margin-bottom: ;
    }
  }
  .active {
    border-bottom: 2px solid var(--orange);
    color: var(--orange);
  }
}

.dropdown {
  // width: $width;
  padding: 10px;
  background-color: var(--menu-grey);
  margin-top: 2px;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: -1px 5px 7px 0px rgba(0, 0, 0, 0.25);
  @include twire-scrollbar;
  & > button {
    width: 100%;
    height: $height;
    // background-color: #1b1c22;
    margin: 3px 0;
    color: var(--light);
    font-size: 14px;
    font-weight: bold;
  }
  .active {
    color: var(--orange);
    background-color: rgba(0, 0, 0, 0.2);
  }
}
