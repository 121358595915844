.container {
  width: 100%;
  & * {
    background-color: var(--placeholder-color);
    width: 100%;
  }
}

.time {
  height: 20px;
  width: 85vw;
  max-width: 150px;
}

.header {
  height: 60px;
  margin: 15px 0;
}

.short {
  height: 24px;
}

.bigImage {
  height: 477px;
  margin: 25px 0;
}

.shortParagraph {
  max-width: 350px;
}

@mixin long-paragraphStyles {
  background-color: transparent !important;
  & > div {
    height: 24px;
    // width: 100%
    margin: 10px 0;
  }
}

.longParagraph1 {
  @include long-paragraphStyles();
  & > :last-child {
    width: 70%;
  }
}

.longParagraph2 {
  @include long-paragraphStyles();
  & > :last-child {
    width: 30%;
  }
}
