@import "../../../../../../../styles/breakpoints";

.chartContainer {
  background: #202229;
  padding: 30px;
  tspan {
    fill: #ffffffb3 !important;
    font-weight: bold;
    font-size: 12px;
  }
  [class*="recharts-surface"] {
    height: 27px0 !important;
  }
}

.container {
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .ring {
    height: 70px;
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--orange);
    border-radius: 100%;
    margin-right: 20px;
    @media (max-width: mq(team, statistics, chart, mobile)) {
      margin-right: 14px;
    }
    svg {
      height: 24px;
      width: 24px;
      path {
        fill: var(--orange);
      }
    }
  }
}

.overall {
  margin-left: auto;
  font-size: 50px;
  line-height: 60px;
  color: var(--light);
  font-family: Rift-Bold;
  @media (max-width: mq(team, statistics, chart, mobile)) {
    display: none;
  }
}

.stat {
  font-size: 24px;
  color: var(--light);
  font-family: Rift-Bold;
}

.description {
  font-family: Open-Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--light);
  opacity: 0.5;
}

.toolTipContainer {
  background: var(--tooltip-dark);
  padding: 10px;
  max-width: 135px;
  & > * {
    color: var(--light);
  }
  & > div {
    text-align: center;
    & > * {
      font-family: open-sans;
      font-size: 12px;
    }
    & > :first-child {
      font-size: 24px;
      font-family: Rift-Bold;
    }
    & > :last-child {
      font-size: 10px;
      opacity: 0.5;
    }
  }
}
