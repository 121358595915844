.container {
  color: var(--light);
  width: 100%;
  .selector {
    background: var(--dark);
    display: flex;
    cursor: pointer;
    .trigger {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .focused {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 14px;
    font-size: 14px;
  }
  .title {
    font-family: Open-Sans;
    display: inline-block;
    font-size: 12px;
    opacity: 0.5;
    text-transform: uppercase;
  }
}

.dropdownItem {
  padding: 15px 34px 15px 14px;
  font-size: 14px;
  color: var(--light);
  opacity: 0.7;
}

.selected {
  background-color: var(--grey);
  opacity: 1;
}

.dropdown {
  background: var(--dark);
  margin-top: 2px;
}
