.container {
  padding: 0 20px;
}

.searchBoarder {
  opacity: 0.15;
}

.button {
  height: 50px;
  width: 100%;
}

.buttonLink {
  width: 48%;
  display: block;
  button {
    font-weight: bold;
  }
}
.navItem {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 82px;
  width: 100%;
  min-width: 70px;
  .svgWrapper {
    height: 27px;
  }
  svg {
    margin-bottom: 10px;
    opacity: 0.3;
  }
  p {
    color: var(--light);
    font-size: 14px;
    font-weight: normal;
  }
}
.mobileNav {
  & > * {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  background-color: var(--grey);
  border: 1px solid var(--light-ash);
  border-right: none;
  border-left: none;
  overflow-x: auto;
}

// .selection {
//   & > * {
//     -webkit-touch-callout: none;
//     -webkit-user-select: none;
//     -khtml-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none !important;
//     height: 70px;
//     & > :nth-child(1) {
//       padding-left: 20px;
//     }
//     & > * {
//       height: 70px;
//     }
//   }
//   & > :nth-child(1) {
//     li {
//       padding-left: 15px !important;
//     }
//   }
// }

.gear {
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  margin: 0 10px;
  svg {
    path {
      opacity: 0.7;
      fill: var(--light);
    }
  }
}

.userProfile {
  margin-right: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
