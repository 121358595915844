@import "../../../../../styles/breakpoints";

.container {
  display: flex;
  background-color: var(--grey);
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  a {
    height: 60px;
    // max-width: 190px;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--light);
    font-weight: bold;
    min-width: fit-content;
  }
  .active {
    color: var(--orange);
    border-bottom: 2px solid var(--orange);
  }
}

.blue {
  justify-content: center;
  @media (max-width: 500px) {
    gap: unset;
    justify-content: unset;
  }
  .active {
    color: var(--blue);
    border-bottom: 2px solid var(--blue);
  }
  a {
    font-family: Rift;
    padding: 0 25px;
    @media (max-width: 500px) {
      max-width: unset;
    }
  }
}
