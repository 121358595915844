.container {
  $offwhite: rgba(255, 255, 255, 0.1);
  height: 306px;
  width: 100%;
  padding: 50px 30px 0 30px;
  .caption {
    height: 60px;
    width: 100%;
    max-width: 394px;
    background: $offwhite;
    margin-bottom: 41px;
  }
  .content {
    display: flex;
    & > :not(:first-child) {
      margin-left: 30px;
    }
  }
  .contentCol {
    & > :nth-child(1) {
      height: 18px;
      width: 43px;
      background: $offwhite;
      max-width: 10vw;
    }
    & > :nth-child(2) {
      margin-top: 4px;
      width: 79px;
      height: 25px;
      background: $offwhite;
      max-width: 15vw;
    }
  }
}
