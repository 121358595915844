.placeholderTile {
  height: 97px;
  width: 100%;
  background-color: var(--grey);
  margin-bottom: 4px;
  margin-top: 4px;
}

.container {
  width: 100%;
}
