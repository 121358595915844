.container {
  display: flex;
  align-items: center;
  & > :first-child,
  & > :last-child {
    height: 1px;
    width: 100%;
    background: var(--orange);
    transition: 0.3s;
  }
  & :nth-child(2) {
    margin: 0 24px;
  }
}
