@import "../../../../styles/breakpoints";

.container {
  display: flex;
  width: 100%;
  @media (max-width: mq(ranking, tab)) {
    flex-direction: column;
  }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}

.right {
  max-width: 280px;
  margin-left: 20px;
  width: 100%;
  @media (max-width: mq(ranking, tab)) {
    max-width: unset;
    margin-left: 0px;
  }
}

.left {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.heading {
  height: 40px;
  background: rgba(52, 55, 65, 0.9);
  color: white;
  font-weight: bold;
  padding: 0px 30px;
  @media (max-width: mq(ranking, mobile)) {
    padding: 0px 20px;
  }
}

.rank {
  width: 30px;
}

.team {
  margin-left: 30px;
}

.point {
  margin-left: auto;
  margin-right: 60px + 24px;
  @media (max-width: mq(ranking, mobile)) {
    margin-right: 10px + 24px;
  }
}

// .region {
//   margin-right: 130px;
//   @media (max-width: mq(ranking, mobile)) {
//     display: none;
//   }
// }

.top {
  color: var(--light);
  h2 {
    font-weight: bold;
    font-size: 24px;
  }
  p {
    opacity: 0.3;
    font-family: Open-Sans;
  }
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  h3 {
    font-size: 16px;
    color: var(--light);
  }
  p {
    font-size: 14px;
    opacity: 0.7;
    color: var(--light);
    font-family: Open-Sans;
  }
}
