.passwordsRow {
  display: flex;
  & > * {
    max-width: 48%;
    display: flex;
    flex-direction: column;
    input {
      margin-top: auto;
    }
  }
  justify-content: space-between;
}

.rowResponsive {
  width: 100%;
  @media (max-width: 550px) {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    .newsLetter {
      margin-top: 10px;
    }
    & > * {
      max-width: 100%;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
