.placeholder {
  background-color: var(--placeholder-color);
}

.tournamentName {
  height: 60px;
  max-width: 500px;
  width: 40vw;
  margin-right: 5px;
}

.badge {
  min-width: 75px;
  min-height: 75px;
}
