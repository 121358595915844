@import "../../../../../styles/breakpoints";

.container {
  display: flex;
  width: 100%;
  @media (max-width: mq(article, tab)) {
    flex-direction: column;
  }
  @media (max-width: mq(featured, mobile)) {
    margin-top: 22px;
  }
}

.postButton {
  max-width: 67px;
  min-height: 40px;
  max-height: 40px;
}

.title {
  color: var(--light) !important;
  font-size: 50px;
  line-height: 60px;
  font-weight: bold;
  margin: 0;
  @media (max-width: mq(article, mobile)) {
    font-size: 30px;
  }
}

.article {
  h2 {
    margin-bottom: 17px;
    opacity: 0.7;
    span {
      color: var(--light) !important;
      font-weight: bold;
      font-size: 18px;
      font-family: Open-Sans;
    }
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

.textAreaWrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 20px;
  position: relative;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(32, 34, 41, 0.9);
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  font-family: rift-bold;
  color: var(--light);
  flex-wrap: wrap;
}

.content {
  & > p > br {
    display: none;
  }
  li > span {
    margin: 0 5px;
  }
  img {
    margin-bottom: 10px;
  }
  strong,
  em,
  span,
  a {
    font-family: Open-Sans;
  }
  a {
    color: var(--orange);
  }
  p {
    color: var(--light);
    opacity: 0.7;
    font-family: Open-Sans;
    font-size: 16px;
    margin: 8px 0;
  }
  h3 {
    margin-top: 20px;
    color: var(--light);
  }
  div[class^="se-component"] {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ul {
    color: var(--light);
    li {
      display: flex;
      align-items: center;
      font-family: Open-Sans;
      &::before {
        content: "";
        display: inline-block;
        height: 5px;
        width: 5px;
        background-color: var(--orange);
        margin-right: 10px;
      }
    }
  }
}

.left {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.right {
  margin-left: 20px;
  max-width: 280px;
  min-width: 280px;
  @media (max-width: mq(article, tab)) {
    margin-top: 20px;
    max-width: unset;
    margin-left: 0;
  }
}

.timeWrapper {
  color: var(--orange);
  font-weight: bold;
  font-size: 16px;
}
