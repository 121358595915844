.navItem {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  .indicatorWrapper {
    opacity: 0;
    right: 0;
    height: 90px;
    width: 0px;
    position: absolute;
    background-image: linear-gradient(
      270deg,
      rgba(240, 171, 46, 0.2) 0%,
      rgba(41, 42, 47, 0) 100%
    );
    pointer-events: none;
    transition: width 0.2s;
    .indicator {
      position: absolute;
      height: 100%;
      width: 2px;
      right: 0;
      background-color: var(--orange);
    }
  }
}

.active {
  svg {
    opacity: 1 !important;
    path {
      fill: var(--orange);
    }
  }
  p {
    color: var(--orange);
  }
  .indicatorWrapper {
    opacity: 1;
    width: 45%;
  }
}

.top {
  .indicatorWrapper {
    top: 0;
    background-image: linear-gradient(
      rgba(240, 171, 46, 0.2) 0%,
      rgba(41, 42, 47, 0) 100%
    );
    transition: height 0.2s;
    .indicator {
      width: 100%;
      height: 2px;
      top: 0;
    }
  }
  &.active {
    .indicatorWrapper {
      opacity: 1;
      width: 100%;
      height: 45%;
    }
  }
}

.bottom {
  .indicatorWrapper {
    bottom: 0;
    background-image: linear-gradient(
      rgba(41, 42, 47, 0) 0%,
      rgba(240, 171, 46, 0.2) 100%
    );
    transition: height 0.2s;
    .indicator {
      width: 100%;
      height: 2px;
      bottom: 0;
    }
  }
  &.active {
    .indicatorWrapper {
      opacity: 1;
      width: 100%;
      height: 45%;
    }
  }
}

.navLink {
  width: 100%;
}

.blue {
  .indicatorWrapper {
    background-image: linear-gradient(
      270deg,
      rgba(90, 192, 236, 0.2) 0%,
      rgba(41, 42, 47, 0) 100%
    );
    .indicator {
      background-color: var(--blue);
    }
  }
  .top {
    .indicatorWrapper {
      background-image: linear-gradient(
        rgba(90, 192, 236, 0.2) 0%,
        rgba(41, 42, 47, 0) 100%
      );
    }
  }

  .bottom {
    .indicatorWrapper {
      background-image: linear-gradient(
        rgba(41, 42, 47, 0) 0%,
        rgba(90, 192, 236, 0.2) 0%
      );
    }
  }

  &.active {
    svg {
      path {
        fill: var(--blue) !important;
      }
    }
    p {
      color: var(--blue);
    }
  }
}
