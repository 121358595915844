.navWrapper {
  height: calc(100vh - 80px);
  min-width: 120px;
  position: sticky;
  left: 0;
  top: 80px;
  overflow-y: auto;
  z-index: 10;
}

.nav {
  margin-top: 2px;
  height: calc(100% - 2px);
  padding: 40px 0px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.joinUs {
  transform: rotate(-90deg);
  color: var(--light);
  opacity: 0.3;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 52px;
  div {
    width: 27px;
    height: 2px;
    background-color: var(--light);
    margin-left: 10px;
  }
}

// .joinSection {
// }

.socials {
  text-align: center;
  margin: 60px auto 0 auto;
  svg {
    opacity: 0.7;
  }
  & > :first-child {
    margin-bottom: 0;
    margin-top: 0;
  }
  & > :last-child {
    margin-bottom: 0;
    margin-top: 0;
  }
  a {
    display: block;
    margin: 20px 0;
  }
}
