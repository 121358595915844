.container {
  $offwhite: rgba(255, 255, 255, 0.1);
  .top {
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    & > :nth-child(1) {
      height: 60px;
      width: 80px;
      background-color: $offwhite;
      margin-right: 10px;
    }
    & > :nth-child(2) {
      width: 100%;
      & > * {
        height: 20px;
        width: 100%;
        background-color: $offwhite;
      }
      & > :first-child {
        margin-bottom: 5px;
      }
      & > :nth-child(2) {
        width: 50%;
      }
    }
  }
  .bottom {
    height: 20px;
    width: 100%;
    background-color: $offwhite;
    margin-top: 10px;
  }
}
