.container {
  @media (max-width: mq(home, small)) {
    padding: 10px 10px !important;
  }
}

.tournamentImageP {
  background: var(--placeholder-color);
  width: 60px;
  height: 60px;
  margin-right: 14px;
}

.headingP {
  background: var(--placeholder-color);
  height: 22px;
  margin-bottom: 5px;
  width: 80%;
  max-width: 500px;
}

.detailRow {
  display: flex;
  & > * {
    background: var(--placeholder-color);
    width: 59px;
    height: 14px;
  }
  & > :not(:first-child) {
    margin-left: 10px;
  }
}
